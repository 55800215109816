import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, useTheme } from "@mui/material";

import { tokens } from "../../../../theme";
import { useGlobalStore } from "../../../../store/globalStore.tsx";
import { Table } from "../../../../components/table/Table";
import { InvoiceInfo } from "../../../../components/InvoiceInfo/InvoiceInfo";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

const BlocInvoicesByCustomer = ({ id }) => {
  const store = useGlobalStore();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(true);
  const [customersData, setCustomersData] = useState([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const result = await store.invoice.getInvoicesByUserId({ id });
        if (result.success) {
          setCustomersData(result.data);
          setIsLoading(false);
        } else {
          // console.error(result.error);
        }
      } catch (error) {
        console.log("fetchInvoicesError");
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
      }
    };
    if (id !== false) {
      fetchInvoices();
    }
  }, [id]);

  const columns = [
    {
      field: "numero",
      headerName: "NUMÉRO",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "client",
      headerName: "CLIENT",
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "creeLe",
      headerName: "ECHÉANCE",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "officeNumber",
      headerName: "TOTAL HT",
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "totalTTC",
      headerName: "TOTAL TTC",
      type: "string",
      flex: 1,
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "statut",
      headerName: "STATUT",
      type: "string",
      flex: 1,
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      headerName: "EDITER",
      type: "string",
      flex: 1,
      headerAlign: "left",
      renderCell: ({ row: { id } }) => (
        <strong>
          <Link to={`/invoice/edit/${id}`}>
            <Button
              variant="contained"
              size="small"
              borderRadius="5px"
              style={{
                backgroundColor: colors.grey[700],
                color: colors.grey[100],
              }}
            >
              ouvrir
            </Button>
          </Link>
        </strong>
      ),
    },
  ];

  if (isLoading) {
    return <></>
  }

  if (customersData.length === 0) {
    return (
      <Box m="40px 0 0 0" maxHeight="75vh" width={"100%"}>
        <h1> Aucune facture pour ce client</h1>
      </Box>
    );
  }

  return (
    <Box
      m="40px 0 0 0"
      maxHeight="75vh"
      width={"100%"}
      sx={{
        "& .MuiDataGrid-root": {
          maxHeight: "70vh",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Table Data={customersData} columns={columns} />
        </Grid>
        <Grid item xs={12}>
          <InvoiceInfo></InvoiceInfo>
        </Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
};

export default BlocInvoicesByCustomer;
