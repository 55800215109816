import React from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import logo_cevep from "../../../img/logo/logo_cevep.webp";

export function GeneratePdf(invoiceDataObject) {
  const invoiceData = invoiceDataObject.invoiceData;

  const taxRates = {};
  let totalAmount = 0;

  // Vérifier que les données de la facture sont disponibles et traiter les lignes
  if (invoiceData && invoiceData.invoiceLines) {
    invoiceData.invoiceLines.forEach((line) => {
      const amountHT = (line.price / 100) * line.quantity; // Conversion en euros
      const amountTVA = amountHT * (line.taxRate.amount / 100);
      const amountTTC = amountHT + amountTVA;
      totalAmount += amountTTC;

      // Stocker les informations de TVA pour chaque taux de taxe
      if (!taxRates[line.taxRate.amount]) {
        taxRates[line.taxRate.amount] = {
          value: line.taxRate.amount,
          totalAmountTVA: 0,
        };
      }
      taxRates[line.taxRate.amount].totalAmountTVA += amountTVA;
    });
  }

  if (!invoiceData) {
    return null; // Renvoie null si les données de la facture ne sont pas disponibles
  }

  const downPayment = invoiceData.payment?.DepositAmount || 0;
  const remainingAmount = totalAmount - downPayment;
  const installmentSchedules = invoiceData.payment?.installmentSchedules || [];
  const totalInstallments = installmentSchedules.length;

  const styles = {
    page: {
      flexDirection: "column",
      padding: "12mm 15mm 18mm",
      fontSize: 10,
    },
    tableHeader: {
      flexDirection: "row",
      backgroundColor: "#f0f0f0",
      padding: 5,
    },
    tableCell: {
      borderRightWidth: 1,
      borderRightColor: "#ccc",
      padding: "10px",
    },
    totals: {
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: 20,
    },
    paymentTable: {
      marginTop: 20,
      borderWidth: 1,
      borderColor: "#ccc",
      width: "100%",
    },
    footer: {
      position: "absolute",
      bottom: 40,
      textAlign: "center",
      width: "100%",
    },
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ alignItems: "center" }}>
          <Image src={logo_cevep} style={{ width: 200 }} />
        </View>

        {/* En-tête de la facture */}
        <View style={{ marginTop: 50, flexDirection: "row" }}>
          <View style={{ width: "50%", alignItems: "center", fontWeight: "700" }}>
            <Text style={{ fontWeight: "bold" }}>16 F rue des Jardins</Text>
            <Text style={{ fontWeight: "bold" }}>95480 PIERRELAYE</Text>
            <Text style={{ fontWeight: "bold" }}>Tél. : 01.34.32.53.53</Text>
            <Text style={{ fontSize: 8, color: "#33b540", fontWeight: "bold" }}>
              E. Mail. : pubccvep@gmail.com
            </Text>
            <Text style={{ marginTop: 20, fontWeight: "bold" }}>
              Facture {invoiceData.number}
            </Text>
            <Text>{`Pierrelaye, le ${new Date(invoiceData.issuedAt).toLocaleDateString("fr-FR")}`}</Text>
          </View>

          <View style={{ width: "50%", alignItems: "center" }}>
            {invoiceData.customer.companyName && (
              <Text>Client: {invoiceData.customer.companyName}</Text>
            )}
            {invoiceData.customer.numberSiret && (
              <Text>N°SIRET: {invoiceData.customer.numberSiret}</Text>
            )}
            {invoiceData.customer.numberTva && (
              <Text>N°TVA: {invoiceData.customer.numberTva}</Text>
            )}
          </View>
        </View>

        {/* Tableau des lignes de facture */}
        <View
          style={{
            width: "100%",
            marginTop: 20,
            borderWidth: 1,
            borderColor: "#ccc",
          }}
        >
          <View style={styles.tableHeader}>
            <View style={{ width: "55%", ...styles.tableCell }}>
              <Text style={{ fontWeight: "bold" }}>DESIGNATION</Text>
            </View>
            <View style={{ width: "10%", ...styles.tableCell }}>
              <Text style={{ fontWeight: "bold" }}>QTE</Text>
            </View>
            <View style={{ width: "20%", ...styles.tableCell }}>
              <Text style={{ fontWeight: "bold" }}>PRIX UNITAIRE EUROS</Text>
            </View>
            <View style={{ width: "15%", padding: "10px" }}>
              <Text style={{ fontWeight: "bold" }}>MONTANT H.T EUROS</Text>
            </View>
          </View>

          {invoiceData.invoiceLines.map((line, index) => (
            <View
              key={index}
              style={{
                flexDirection: "row",
                borderTopWidth: 1,
                borderTopColor: "#ccc",
                paddingVertical: 5,
              }}
            >
              <View style={{ width: "55%", ...styles.tableCell }}>
                <Text>{line.description}</Text>
              </View>
              <View style={{ width: "10%", ...styles.tableCell }}>
                <Text>{line.quantity}</Text>
              </View>
              <View style={{ width: "20%", ...styles.tableCell }}>
                <Text>
                  {(line.price / 100).toLocaleString("fr-FR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Text>
              </View>
              <View style={{ width: "15%", padding: "10px" }}>
                <Text>
                  {((line.price / 100) * line.quantity).toLocaleString("fr-FR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Text>
              </View>
            </View>
          ))}
        </View>

        {/* Section Totaux */}
        <View style={styles.totals}>
          <View style={{ width: "100%", textAlign: "right", marginTop: 20 }}>
            {Object.entries(taxRates).map(([rate, values]) => (
              <Text key={rate}>
                {`Taux de TVA ${values.value}% : ${values.totalAmountTVA.toLocaleString("fr-FR", { minimumFractionDigits: 2 })} €`}
              </Text>
            ))}
            <Text>{`Montant total: ${totalAmount.toLocaleString("fr-FR", { minimumFractionDigits: 2 })} €`}</Text>
            {downPayment > 0 && (
              <Text>{`Acompte versé: ${downPayment.toLocaleString("fr-FR", { minimumFractionDigits: 2 })} €`}</Text>
            )}
            {downPayment > 0 && (
              <Text>{`Montant restant à payer: ${remainingAmount.toLocaleString("fr-FR", { minimumFractionDigits: 2 })} €`}</Text>
            )}
          </View>
        </View>

        {/* Tableau des paiements échelonnés (si plusieurs versements) */}
        {totalInstallments > 1 && (
          <View style={styles.paymentTable}>
            <View style={styles.tableHeader}>
              <View style={{ width: "25%", ...styles.tableCell }}>
                <Text style={{ fontWeight: "bold" }}>Paiement N°</Text>
              </View>
              <View style={{ width: "25%", ...styles.tableCell }}>
                <Text style={{ fontWeight: "bold" }}>Date d'échéance</Text>
              </View>
              <View style={{ width: "25%", ...styles.tableCell }}>
                <Text style={{ fontWeight: "bold" }}>Montant à payer</Text>
              </View>
              <View style={{ width: "25%", padding: "10px" }}>
                <Text style={{ fontWeight: "bold" }}>Montant Total</Text>
              </View>
            </View>
            {installmentSchedules.map((schedule, index) => {
              const amountPerInstallment = remainingAmount / totalInstallments;
              const totalPaid = amountPerInstallment * (index + 1);

              return (
                <View
                  key={schedule.id}
                  style={{
                    flexDirection: "row",
                    borderTopWidth: 1,
                    borderTopColor: "#ccc",
                    paddingVertical: 5,
                  }}
                >
                  <View style={{ width: "25%", ...styles.tableCell }}>
                    <Text>{schedule.installmentNumber}</Text>
                  </View>
                  <View style={{ width: "25%", ...styles.tableCell }}>
                    <Text>
                      {new Date(schedule.dueDate).toLocaleDateString("fr-FR")}
                    </Text>
                  </View>
                  <View style={{ width: "25%", ...styles.tableCell }}>
                    <Text>
                      {amountPerInstallment.toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                      })}{" "}
                      €
                    </Text>
                  </View>
                  <View style={{ width: "25%", padding: "10px" }}>
                    <Text>
                      {totalPaid.toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                      })}{" "}
                      €
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}

        {/* Section Pied de page */}
        <View style={styles.footer}>
          <Text style={{ fontSize: 10, fontWeight: "bold", color: "#33b540" }}>
            Siège social: Pierrelaye (Val d'Oise) S.A.R.L. au Capital de 8000€. RCS: 353 688 591 00067 Code APE: 7311Z
          </Text>
        </View>
      </Page>
    </Document>
  );
}
