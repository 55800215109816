import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import logo_cevep from "../../../img/logo/logo_cevep.webp";
import "./previewInvoice.css";

export function CreateViewInvoice(invoiceDataObject) {
  console.log(invoiceDataObject, "invoiceDataObject");
  const invoiceData = invoiceDataObject.invoiceData;

  if (!invoiceData) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  const taxRates = {};
  let totalAmount = 0;

  if (invoiceData && invoiceData.invoiceLines) {
    invoiceData.invoiceLines.forEach((line) => {
      const amountHT = (line.price / 100) * line.quantity;
      const amountTVA = amountHT * (line.taxRate.amount / 100);
      const amountTTC = amountHT + amountTVA;
      totalAmount += amountTTC;

      if (!taxRates[line.taxRate.amount]) {
        taxRates[line.taxRate.amount] = {
          value: line.taxRate.amount,
          totalAmountTVA: 0,
        };
      }
      taxRates[line.taxRate.amount].totalAmountTVA += amountTVA;
    });
  }

  const hasPaymentData = invoiceData.payment ? true : false;
  const downPayment = hasPaymentData
    ? invoiceData.payment.DepositAmount / 100 || 0
    : 0;
  const remainingAmount = totalAmount - downPayment;
  const totalInstallments = hasPaymentData
    ? invoiceData.payment.installmentSchedules?.length || 0
    : 0;

  return (
    <>
      {invoiceData && (
        <Box className="preview-invoice">
          <Box display="flex" flexDirection="column">
            <Box
              width={"100%"}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={logo_cevep} alt="" style={{ width: "300px" }} />
            </Box>

            <Box marginTop="50px" display="flex" flexDirection="row">
              <Box
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography fontWeight={700}>16 F rue des Jardins</Typography>
                <Typography fontWeight={700}>95480 PIERRELAYE</Typography>
                <Typography fontWeight={700}>Tél. : 01.34.32.53.53</Typography>
                <Typography fontSize={8} color="#33b540" fontWeight={700}>
                  E. Mail. : pubccvep@gmail.com
                </Typography>
                <Typography marginTop="20px" fontWeight={700}>
                  Facture {invoiceData.number}
                </Typography>
                <Typography marginBottom="20px">
                  Pierrelaye, le{" "}
                  {new Date(invoiceData.issuedAt).toLocaleDateString("fr-FR")}
                </Typography>
              </Box>

              <Box
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  fontWeight: 700,
                }}
              >
                {invoiceData.customer.companyName && (
                  <Typography>
                    Client: {invoiceData.customer.companyName}
                  </Typography>
                )}
                {invoiceData.customer.numberSiret && (
                  <Typography>
                    N°SIRET: {invoiceData.customer.numberSiret}
                  </Typography>
                )}
                {invoiceData.customer.numberTva && (
                  <Typography>
                    N°TVA: {invoiceData.customer.numberTva}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box style={{ width: "100%" }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "70%" }}>
                        DESIGNATION
                      </TableCell>
                      <TableCell> QTE</TableCell>
                      <TableCell>PRIX UNITAIRE EUROS</TableCell>
                      <TableCell>MONTANT H.T EUROS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData.invoiceLines.map((line, index) => (
                      <TableRow key={index}>
                        <TableCell>{line.description}</TableCell>
                        <TableCell>{line.quantity}</TableCell>
                        <TableCell>
                          {(line.price / 100).toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          €
                        </TableCell>
                        <TableCell>
                          {((line.price / 100) * line.quantity).toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          €
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              width="100%"
              marginTop="20px"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                width="100%"
                textAlign={"right"}
                marginTop="20px"
              >
                {Object.entries(taxRates).map(([rate, values]) => (
                  <Box key={rate}>
                    <Typography>
                      Taux de TVA {values.value}% :{" "}
                      {(values.totalAmountTVA).toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      €
                    </Typography>
                  </Box>
                ))}
                <Typography variant="p">
                  Montant total:{" "}
                  {(totalAmount).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  €
                </Typography>

                {downPayment > 0 && (
                  <Typography variant="p" color="primary">
                    Acompte versé:{" "}
                    {(downPayment).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </Typography>
                )}

                {downPayment > 0 && (
                  <Typography variant="p" color="secondary">
                    Montant restant à payer:{" "}
                    {(remainingAmount).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </Typography>
                )}

                {totalInstallments > 1 && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Paiement N°</TableCell>
                          <TableCell>Date d'échéance</TableCell>
                          <TableCell>Montant à payer</TableCell>
                          <TableCell>Montant Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceData.payment.installmentSchedules.map(
                          (schedule, index) => {
                            const amountPerInstallment =
                              remainingAmount / totalInstallments;
                            const totalPaid =
                              amountPerInstallment * (index + 1);

                            return (
                              <TableRow key={schedule.id}>
                                <TableCell>
                                  {schedule.installmentNumber}
                                </TableCell>
                                <TableCell>
                                  {new Date(
                                    schedule.dueDate
                                  ).toLocaleDateString("fr-FR")}
                                </TableCell>
                                <TableCell>
                                  {(amountPerInstallment).toLocaleString(
                                    "fr-FR",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}{" "}
                                  €
                                </TableCell>
                                <TableCell>
                                  {(totalPaid).toLocaleString("fr-FR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  €
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              left: "0",
              right: "0",
              bottom: "40px",
              position: "absolute",
              textAlign: "center",
              width: "100%",
              fontWeight: "700",
              color: "#33b540",
            }}
          >
            Siège social: Pierrelaye (Val d'Oise) S.A.R.L. au Capital de l8000E.
            RCS: 353 688 591 00067 Code APE: 7311Z
          </Box>
        </Box>
      )}
    </>
  );
}
