import { Box, useTheme } from "@mui/material";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { tokens } from "../../theme";

export function InvoiceInfo () {
  const store = useGlobalStore();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box spacing={2} backgroundColor={colors.neutral[100]}>
    
      </Box>
    </>
  );
};
