/* Vos styles ici */
export const textFieldStyleModal = {
  color: "black !important",
  backgroundColor: "#f0f0f0", // Mettez ici la couleur de fond souhaitée
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black", // Vous pouvez également personnaliser la bordure
    },
    "&:hover fieldset": {
      borderColor: "red", // Couleur de bordure au survol
    },
    "&.Mui-focused fieldset": {
      borderColor: "purple", // Couleur de bordure lorsque le champ est focalisé
    },
    "& .MuiOutlinedInput-input": {
      color: "black", // Couleur du texte
    },
    "& .MuiFormControl-root": {
      width: "100%", // Correction ici : la propriété doit être "width", pas "with"
    },
  },
  ".MuiInputBase-root": {
    width: "100%",    
  },
  ".MuiFormControl-root": {
    width: "100%",
  },
};

  

export const textFieldStyleClassic = {
  color: "white !important",
  marginTop: "10px",
};
