import { Box, Button, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import { tokens } from "../../../theme";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Dashboard from "../../dashboard/DashboardIndex.tsx";
import { useParams, redirect } from "react-router-dom";
import BlocInvoicesByCustomer from "./components/BlocInvoicesByCustomer";
import FormByCustomer from "./components/FormCustomer";

const EditCustomer = () => {
  const { id } = useParams();
  const store = useGlobalStore();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (!id) {
      return <redirect to="/customers" />;
    }
    return () => {};
  }, [id]);

  const href = `/invoice/edit/${id}`;

  return (
    <>
      <Dashboard
        title={"Clients"}
        subtitle={"Retrouvé toutes vos clients"}
        element={
          <Button href={href} variant="contained" color="secondary">
            <AddIcon />
            Ajouter
          </Button>
        }
      />
      <Box m="20px">
        <Grid container spacing={{ xs: 2, md: 3 }} p={2}>
          <FormByCustomer store={store} id={id} />

          <Grid container spacing={4} item xs={12}>
            <BlocInvoicesByCustomer store={store} id={id} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditCustomer;
